/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BatchRequest_Search_jsonld = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly id: number;
    employer: string;
    name: string | null;
    minSalary: number | null;
    maxSalary: number | null;
    minYearsOfWorkExperience: number | null;
    minHoursPerWeek: number | null;
    degree: string | null;
    sector: string | null;
    educationLevel: string | null;
    educationName: string | null;
    certificate: string | null;
    subjects: Array<string>;
    zipcode: string | null;
    travelDistance: number | null;
    latitude: number | null;
    longitude: number | null;
    isco08: string | null;
    selectionPhase: BatchRequest_Search_jsonld.selectionPhase;
    automaticallyContactCandidates: boolean;
    lastAutoContactDate: string | null;
    autoContactMessage: string | null;
    questions: Array<string>;
};
export namespace BatchRequest_Search_jsonld {
    export enum selectionPhase {
        QUALITY_SELECT = 'qualitySelect',
        STANDARD = 'standard',
    }
}

