/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HubspotContact } from '../models/HubspotContact';
import type { HubspotContact_employer_hubspot_contact } from '../models/HubspotContact_employer_hubspot_contact';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContactService {
    /**
     * Creates a new contact in Hubspot
     * Creates a new contact in Hubspot
     * @returns HubspotContact HubspotContact resource created
     * @throws ApiError
     */
    public static hubspotContactCreate({
        requestBody,
    }: {
        /**
         * The new HubspotContact resource
         */
        requestBody: HubspotContact_employer_hubspot_contact,
    }): CancelablePromise<HubspotContact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
}
