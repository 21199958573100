/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employer_message_candidate } from './Employer_message_candidate';
export type Connection_message_candidate = {
    readonly uuid: string;
    status: Connection_message_candidate.status;
    shareStatus: Connection_message_candidate.shareStatus;
    updatedAt: string;
    employer: Employer_message_candidate;
};
export namespace Connection_message_candidate {
    export enum status {
        QUALITY_SELECT = 'qualitySelect',
        PROSPECT = 'prospect',
        CONCEPT = 'concept',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELED = 'canceled',
    }
    export enum shareStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
    }
}

