/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Question_question_add } from '../models/Question_question_add';
import type { Question_question_list } from '../models/Question_question_list';
import type { Question_question_update } from '../models/Question_question_update';
import type { Question_question_view } from '../models/Question_question_view';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class QuestionService {
    /**
     * Retrieves the collection of Question resources.
     * Retrieves the collection of Question resources.
     * @returns Question_question_list Question collection
     * @throws ApiError
     */
    public static qualitySelectListQuestions({
        page = 1,
        itemsPerPage = 30,
        search,
        searchArray,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        search?: string,
        searchArray?: Array<string>,
    }): CancelablePromise<Array<Question_question_list>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/employerapi/questions',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'search': search,
                'search[]': searchArray,
            },
        });
    }
    /**
     * Creates a Question resource.
     * Creates a Question resource.
     * @returns Question_question_view Question resource created
     * @throws ApiError
     */
    public static qualitySelectAddQuestion({
        requestBody,
    }: {
        /**
         * The new Question resource
         */
        requestBody: Question_question_add,
    }): CancelablePromise<Question_question_view> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/employerapi/questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Replaces the Question resource.
     * Replaces the Question resource.
     * @returns Question_question_view Question resource updated
     * @throws ApiError
     */
    public static qualitySelectUpdateQuestion({
        uuid,
        requestBody,
    }: {
        /**
         * Question identifier
         */
        uuid: string,
        /**
         * The updated Question resource
         */
        requestBody: Question_question_update,
    }): CancelablePromise<Question_question_view> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/employerapi/questions/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Removes the Question resource.
     * Removes the Question resource.
     * @returns void
     * @throws ApiError
     */
    public static qualitySelectDeleteQuestion({
        uuid,
    }: {
        /**
         * Question identifier
         */
        uuid: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/employerapi/questions/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
}
