/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Search_jsonld_search_create = {
    name: string | null;
    minSalary: number | null;
    maxSalary: number | null;
    minYearsOfWorkExperience: number | null;
    minHoursPerWeek: number | null;
    degree: string | null;
    sector: string | null;
    educationLevel: string | null;
    educationName: string | null;
    certificate: string | null;
    subjects: Array<string>;
    zipcode: string | null;
    travelDistance: number | null;
    latitude: number | null;
    longitude: number | null;
    isco08: string | null;
    selectionPhase: Search_jsonld_search_create.selectionPhase;
};
export namespace Search_jsonld_search_create {
    export enum selectionPhase {
        QUALITY_SELECT = 'qualitySelect',
        STANDARD = 'standard',
    }
}

