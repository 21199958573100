/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationLevel_jsonld_search_view } from './EducationLevel_jsonld_search_view';
import type { Sector_jsonld_search_view } from './Sector_jsonld_search_view';
export type Search_jsonld_search_view = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly id: number;
    name: string | null;
    minSalary: number | null;
    maxSalary: number | null;
    minYearsOfWorkExperience: number | null;
    minHoursPerWeek: number | null;
    degree: string | null;
    sector: (Sector_jsonld_search_view | null);
    educationLevel: (EducationLevel_jsonld_search_view | null);
    educationName: string | null;
    certificate: string | null;
    subjects: Array<string>;
    zipcode: string | null;
    travelDistance: number | null;
    latitude: number | null;
    longitude: number | null;
    isco08: string | null;
    selectionPhase: Search_jsonld_search_view.selectionPhase;
    automaticallyContactCandidates: boolean;
    autoContactMessage: string | null;
};
export namespace Search_jsonld_search_view {
    export enum selectionPhase {
        QUALITY_SELECT = 'qualitySelect',
        STANDARD = 'standard',
    }
}

