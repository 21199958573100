import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faPlusLarge } from '@fortawesome/pro-regular-svg-icons/faPlusLarge';
import { faMessage } from '@fortawesome/pro-regular-svg-icons/faMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Link from 'next/link';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, useMemo } from 'react';
import styles from './Button.module.css';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faPlay } from '@fortawesome/pro-regular-svg-icons/faPlay';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faPhoneSlash } from '@fortawesome/pro-regular-svg-icons/faPhoneSlash';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';

export type ButtonThemes = 'secondary' | 'secondaryBorderless' | 'discrete' | 'plain' | 'plainRed';

interface SharedProperties {
  disabled?: boolean;
  iconBefore?: keyof typeof ICONS;
  icon?: keyof typeof ICONS;
  theme?: ButtonThemes;
  small?: boolean;
}

export interface ButtonLinkProperties
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    SharedProperties {
  href: string;
  openInNewTab?: boolean;
}

export interface ButtonProperties
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    SharedProperties {}
const ICONS = {
  phone: faPhone,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  edit: faPencil,
  thumbsDown: faThumbsDown,
  plus: faPlusLarge,
  messages: faMessage,
  clock: faClock,
  play: faPlay,
  magnifiyingGlass: faMagnifyingGlass,
  trash: faTrash,
  check: faCheck,
  identification: faIdCard,
  dots: faEllipsisVertical,
  horizontalDots: faEllipsisH,
  phoneSlash: faPhoneSlash,
  xMark: faXmark,
};

export const ButtonLink = ({
  icon,
  theme,
  children,
  href,
  iconBefore,
  openInNewTab,
  small,
  disabled,
  ...rest
}: ButtonLinkProperties) => {
  const buttonClassName = useMemo(
    () => getButtonClassNames({ theme, small, disabled, icon }),
    [theme, small, disabled, icon]
  );

  return (
    <Link
      href={disabled ? '#' : href}
      target={openInNewTab ? '_blank' : undefined}
      className={buttonClassName}
      {...{ ...rest, ref: undefined }}>
      {!!iconBefore && <FontAwesomeIcon icon={ICONS[iconBefore]} />}
      {!!children && <span>{children}</span>}
      {!!icon && <FontAwesomeIcon icon={ICONS[icon]} />}
    </Link>
  );
};

export const Button = ({
  icon,
  iconBefore,
  theme,
  disabled,
  children,
  type = 'button',
  small,
  ...rest
}: ButtonProperties) => {
  const buttonClassName = useMemo(
    () => getButtonClassNames({ theme, small, icon, disabled }),
    [icon, small, theme, disabled]
  );

  return (
    <button
      type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
      className={buttonClassName}
      {...rest}>
      {!!iconBefore && <FontAwesomeIcon icon={ICONS[iconBefore]} />}
      {!!children && <span>{children}</span>}
      {!!icon && <FontAwesomeIcon icon={ICONS[icon]} />}
    </button>
  );
};

function getButtonClassNames({
  theme,
  small,
  disabled,
  icon,
}: { theme?: ButtonThemes; small?: boolean; disabled?: boolean; icon?: keyof typeof ICONS } = {}) {
  return classnames(styles.button, {
    [styles.secondary]: theme === 'secondary',
    [styles.secondaryBorderless]: theme === 'secondaryBorderless',
    [styles.discrete]: theme === 'discrete',
    [styles.plain]: theme === 'plain',
    [styles.plainRed]: theme === 'plainRed',
    [styles.small]: small,
    [styles.translateIcon]: icon === 'arrowRight',
    [styles.disabled]: disabled,
  });
}
